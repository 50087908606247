import axios from "@/plugins/axios";

export default {
  async listar() {
    return await axios.get(`contratos`);
  },

  async buscar(id) {
    return await axios.get(`contratos/${id}`);
  },

  async buscarContratosDoCliente(id) {
    return await axios.get(`contratos/cliente/${id}`);
  },

  async inserir(dados) {
    return await axios.post(`contratos`, dados);
  },

  async atualizar(id, dados) {
    return await axios.put(`contratos/${id}`, dados);
  },

  async excluirVeiculoDoContrato(id, veiculoId) {
    return await axios.get(`contratos/${id}/veiculo/${veiculoId}`);
  },
};
