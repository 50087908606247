export default {

  ptBr (data) {
    if (!data) return null

    const [ano, mes, dia] = data.split('-')
    return `${dia}/${mes}/${ano}`
  },
  ptBrComHorario (data) {
    if (!data) return null

    const [ano, mes, dia, horas] = data.split(/[., -]/)
    return `${dia}/${mes}/${ano} ${horas}`
  },

  api (data) {
    if (!data) return null

    const [dia, mes, ano] = data.split('/')
    return `${ano}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`
  },

}
