<template>
  <v-container fluid>
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar :to="`/cliente/${$route.params.cliente}/contratos`" />
        </v-col>
      </v-row>
    </v-card-actions>
    <contrato-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import contratos from "@/api/contratos";
import formatarData from "@/utils/formatar-data-input";

export default {
  components: {
    ContratoCampos: () => import("./ContratoCampos"),
  },

  data() {
    return {
      carregando: false,
      validacaoFormulario: {},
      clienteID: null,
    };
  },

  methods: {
    async salvar(contrato) {
      this.carregando = true;
      this.validacaoFormulario = {};
      contrato.cliente_id = this.$route.params.cliente;
      contrato.inicio = formatarData.api(contrato.inicio);
      contrato.termino = formatarData.api(contrato.termino);

      console.log(contrato);

      try {
        await contratos.inserir(contrato);

        this.$router.push(
          {
            name: "Contratos do Cliente",
            params: { cliente: this.$route.params.cliente },
          },
          () =>
            this.$snackbar.mostrar({
              cor: "success",
              mensagem: this.$mensagens._("sucesso_adicionar"),
            })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>
